import type { IProduct } from '@types';

export const useViewedProductStore = defineStore('viewedProductStore', () => {
  const viewedProducts = ref<Array<IProduct>>([]);
  const viewedProductsPagination = ref<any>({});

  return {
    viewedProducts,
    viewedProductsPagination,
  };
});
