import type { IAuthParams } from '@types';

export const useAuth = () => {
  const localePath = useLocalePath();
  const { trackLogin } = useAnalytics();

  const { user } = storeToRefs(useUserStore());
  const { wishlist } = storeToRefs(useWishlistStore());
  const { cart } = storeToRefs(useCartStore());
  const { openList } = storeToRefs(useDrawerStore());
  const { viewedProducts } = storeToRefs(useViewedProductStore());
  const { pending, authData, otpTimer, otpTimerAmount, callback, isAuth, isAuthModal, isOtpGenerated } =
    storeToRefs(useAuthStore());

  const openAuthModal = (callbackValue?: Function): void => {
    if (isAuth.value) {
      return;
    }

    isAuthModal.value = true;

    if (typeof callbackValue === 'function') {
      callback.value = callbackValue;
    }
  };

  const closeAuthModal = (): void => {
    isAuthModal.value = false;
    removeCookie('_redirectTo');
    removeCookie('_openAuth');
  };

  const getOtp = async ({ phone }: { phone?: string }): Promise<void> => {
    pending.value = true;
    isOtpGenerated.value = false;
    authData.value.phone = phone;

    try {
      const result: any = await fetchApiPost('/auth/otp', {
        data: {
          phone,
        },
      });

      authData.value.isRegistered = result?.isRegistered;

      if (result?.isGenerated) {
        authData.value.otp = undefined;
        otpTimer.value = Number(otpTimerAmount.value);
        isOtpGenerated.value = result.isGenerated;
      }
    } catch (error: any) {
      throw createError(error);
    } finally {
      pending.value = false;
    }
  };

  const getAuth = async (params: IAuthParams): Promise<any> => {
    pending.value = true;

    try {
      const result = await fetchApiPost(params?.social ? '/auth/social' : '/auth/phone', {
        data: {
          ...params,
        },
      });

      if (!params?.social) {
        trackLogin({
          method: 'phone',
        });
      }

      if (result?.cart) {
        cart.value = result?.cart;
      }

      if (result?.user) {
        user.value = result?.user;
        wishlist.value = result?.wishlist;
        authData.value = {};
        isOtpGenerated.value = false;
        isAuth.value = true;
      }

      if (callback.value) {
        await callback.value.apply(null);
        callback.value = undefined;
      }

      return {
        ...result,
        method: params?.social || 'phone',
      };
    } finally {
      pending.value = false;
    }
  };

  const logout = async (): Promise<void> => {
    try {
      const result = await fetchApiPost('/auth/logout');

      await removeCookie('_order');

      await navigateTo(localePath('/'));

      cart.value = result?.cart;
      authData.value = {};
      user.value = undefined;
      wishlist.value = undefined;
      isOtpGenerated.value = false;
      isAuth.value = false;

      if (openList.value?.length) {
        openList.value = [];
      }

      viewedProducts.value = [];
    } catch (error: any) {
      throw createError(error);
    }
  };

  return {
    logout,
    openAuthModal,
    closeAuthModal,
    getOtp,
    getAuth,
  };
};
